import * as React from 'react';
import { TaxReportCountry } from 'store/app/enums';
import { MaltaForm } from './forms/malta';
import { VatReportData } from 'store/vat/models';
import { Alert } from 'antd';
import { ReportCssWrapper } from './report-css-wrapper';
import { CyprusForm } from './forms/cyprus';
import { UAEForm } from './forms/uae';
import { IrelandForm } from './forms/ireland';
import { IsleOfManForm } from './forms/isleOfMan';
import { ReactComponent as EyeIcon } from 'assets/images/icons/eyeIcon.svg';
import { ReactComponent as CopyIcon } from 'assets/images/icons/copyIcon.svg';
import { Space } from 'antd';
import { IconButton } from 'components/Custom/buttons';
import { MessageStates } from 'containers/MessageBox';
import { MessageData } from 'store/app/types';
import { turnMessageOn } from 'store/app/actions';
import { useDispatch } from 'react-redux';
interface VatReturnFormsProps {
  taxReportCountry: TaxReportCountry;
  data?: VatReportData;
  //subsectionsMap: Map<string, number>;
  //updateSubsectionsMap: (key: string, value: number) => void;
  resetValues: boolean;
  resetFinished: () => void;
  setActiveKey: (key) => void;
  setFilteredBox: (key) => void;
  //dataLogId: string;
}

export const VatReturnForms: React.FC<VatReturnFormsProps> = ({
  taxReportCountry,
  data,
  //subsectionsMap,
  //updateSubsectionsMap,
  resetValues,
  resetFinished,
  setActiveKey,
  setFilteredBox,
  //dataLogId,
}) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (resetValues) {
      const valueFields = document.querySelectorAll('.value-field-span');
      valueFields.forEach((valueField) => {
        valueField.textContent = '0.00';
      });
      resetFinished();
    }
  }, [resetValues]);
  React.useEffect(() => {
    //updateSubsectionsMap('RESET', 0);
  }, [data]);

  const copyCurrentValue = (boxNumber: string) => {
    const subsectionValueSpan = document.getElementById(
      `subsection${boxNumber}-value-span`
    );
    navigator.clipboard.writeText(subsectionValueSpan?.textContent);

    const message: MessageData = {
      title: '',
      description: 'Copied to clipboard!',
      type: MessageStates.INFO,
    };
    dispatch(turnMessageOn(message));
  };

  const showTransactionByBox = (boxNumber: string) => {
    setActiveKey('2');
    const subsectionBoxNameCell = document.getElementById(
      `subsection${boxNumber}-box-name`
    );
    setFilteredBox(subsectionBoxNameCell?.textContent);
  };

  const renderValueCell = (boxNumber: string, withP = false) => {
    const id = withP
      ? `subsection${boxNumber}-value-p-span`
      : `subsection${boxNumber}-value-span`;
    return (
      <>
        <p id={id} className="value-field-span">
          0.00
        </p>
        <Space className="show-on-hover">
          <IconButton
            type="default"
            className="icon-button-hover"
            style={{
              height: '30px',
              borderRadius: 50,
              display: 'flex',
              alignItems: 'center',
              padding: '14px 10px',
            }}
            icon={
              <EyeIcon className="hover-icon" width={'23px'} height={'23px'} />
            }
            onClick={() => showTransactionByBox(boxNumber)}
          ></IconButton>
          <IconButton
            type="default"
            className="icon-button-hover"
            style={{
              height: '30px',
              borderRadius: 50,
              display: 'flex',
              alignItems: 'center',
              padding: '14px 10px',
            }}
            icon={
              <CopyIcon className="hover-icon" width={'16px'} height={'16px'} />
            }
            onClick={() => copyCurrentValue(boxNumber)}
          ></IconButton>
        </Space>
      </>
    );
  };

  const renderForm = () => {
    if (true) {
      switch (taxReportCountry) {
        case TaxReportCountry.MALTA:
          return (
            <ReportCssWrapper>
              <MaltaForm
                data={data}
                renderValueCell={renderValueCell}
                //subsectionsMap={subsectionsMap}
                //updateSubsectionsMap={updateSubsectionsMap}
              />
            </ReportCssWrapper>
          );
        case TaxReportCountry.CYPRUS:
          return (
            <ReportCssWrapper>
              <CyprusForm
                data={data}
                renderValueCell={renderValueCell}
                //subsectionsMap={subsectionsMap}
                //updateSubsectionsMap={updateSubsectionsMap}
              />
            </ReportCssWrapper>
          );
        case TaxReportCountry.UAE:
          return (
            <ReportCssWrapper>
              <UAEForm
                data={data}
                renderValueCell={renderValueCell}
                //subsectionsMap={subsectionsMap}
                //updateSubsectionsMap={updateSubsectionsMap}
              />
            </ReportCssWrapper>
          );
        case TaxReportCountry.ISLE_OF_MAN:
          return (
            <ReportCssWrapper>
              <IsleOfManForm
                data={data}
                renderValueCell={renderValueCell}
                //subsectionsMap={subsectionsMap}
                //updateSubsectionsMap={updateSubsectionsMap}
              />
            </ReportCssWrapper>
          );
        case TaxReportCountry.IRELAND_DETAILED:
        case TaxReportCountry.IRELAND_STANDARD:
        case TaxReportCountry.IRELAND_RTD:
          return (
            <ReportCssWrapper>
              <IrelandForm
                data={data}
                renderValueCell={renderValueCell}
                //subsectionsMap={subsectionsMap}
                //updateSubsectionsMap={updateSubsectionsMap}
                taxReportCountry={taxReportCountry}
              />
            </ReportCssWrapper>
          );
        default:
          return (
            <Alert
              message="Error"
              description={`No return forms were found for ${taxReportCountry}`}
              type="error"
              showIcon
            />
          );
      }
    } else {
      return null; //(
      //   <Alert
      //     message="Error"
      //     description={`No data loaded `}
      //     type="error"
      //     showIcon
      //   />
      // );
    }
  };

  return renderForm();
};
