import * as React from 'react';
import { VatReportData } from 'store/vat/models';

interface MaltaFormProps {
  data?: VatReportData;
  renderValueCell: (boxNumber: string) => void;
}
export const MaltaForm: React.FC<MaltaFormProps> = ({
  data,
  renderValueCell,
}) => {
  React.useEffect(() => {
    if (data === undefined || data === null) return;
    const currencySignSpans = document.querySelectorAll('.currency-sign');
    currencySignSpans.forEach((currencySignSpan) => {
      if (data.currencySymbol !== null) {
        currencySignSpan.textContent = data.currencySymbol;
      }
    });

    data?.sections.map((section, i) => {
      const sectionNameCell = document.querySelector(`#section10${i}-name`);

      if (sectionNameCell) {
        sectionNameCell.textContent = section.name;
      }
    });
    let box43Value = 0;
    let box44Value = 0;
    let subsectionValueSpan45;

    data?.subsections.map((subsection) => {
      const boxNumber = subsection?.code.replace(/^Box\s+/, '');
      const subsectionBoxNameCell = document.getElementById(
        `subsection${boxNumber}-box-name`
      );
      /*       const subsectionValueCell = document.getElementById(
        `subsection${boxNumber}-value`
      ); */

      const subsectionNameCell = document.getElementById(
        `subsection${boxNumber}-name`
      );
      const subsectionValueSpan = document.getElementById(
        `subsection${boxNumber}-value-span`
      );
      if (subsectionNameCell !== null) {
        subsectionNameCell.textContent = subsection.name;
      }

      if (
        subsectionBoxNameCell !== null && //subsectionValueCell !== null &&
        subsectionValueSpan !== null
      ) {
        subsectionBoxNameCell.textContent = subsection.code;
        const textContent: any = subsection.net;
        //subsectionValueCell.textContent = textContent.toFixed(2);
        subsectionValueSpan.textContent = textContent.toFixed(2);
        if (boxNumber === '45') subsectionValueSpan45 = subsectionValueSpan;
        if (boxNumber === '44') box44Value = subsection.net;
        if (boxNumber === '43') box43Value = subsection.net;
      }
    });
    if (data.subsections && data.subsections.length > 0) {
      const box45Value = box43Value - box44Value;
      subsectionValueSpan45.textContent = box45Value.toFixed(2);
    } //box 45 = box 43 - box 44
  }, [data]);

  return (
    <>
      <table className="vatreporttable table">
        <thead>
          <tr>
            <th id="section100-name">Intra-Community and Non-EU Trade</th>
            <th></th>
            <th style={{ width: 130, textAlign: 'center' }}>
              Value - <span className="currency-sign"></span>
            </th>
            <th></th>
            <th style={{ width: 130, textAlign: 'center' }}>
              Output Tax - <span className="currency-sign"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id="subsection1-name">
              Exempt IC Supplies of Goods and Supplies of Services where
              customer is liable for the tax
            </td>
            <td id="subsection1-box-name" className="box">
              Box 1
            </td>
            <td
              id="subsection1-value"
              className="rightalign value-field"
              tabIndex={1}
            >
              {renderValueCell('1')}
            </td>
            <td className="nbr nbb"></td>
            <td className="nbb"></td>
          </tr>
          <tr>
            <td id="subsection2-name">
              Supplies of Goods and Services where Place of Supply is outside
              Malta - EU and Non EU
            </td>
            <td id="subsection2-box-name" className="box">
              Box 2
            </td>
            <td
              id="subsection2-value"
              className="rightalign value-field"
              tabIndex={2}
            >
              {renderValueCell('2')}
            </td>
            <td className="nbr"></td>
            <td className=""></td>
          </tr>
          <tr>
            <td id="subsection3-name">
              IC Acquisitions of Goods and Services received from other EU
              Member States
            </td>
            <td id="subsection3-box-name" className="box">
              Box 3
            </td>
            <td
              id="subsection3-value"
              className="rightalign value-field"
              tabIndex={3}
            >
              {renderValueCell('3')}
            </td>
            <td id="subsection6-box-name" className="box">
              Box 6
            </td>
            <td
              id="subsection6-value"
              className="rightalign value-field"
              tabIndex={6}
            >
              {renderValueCell('6')}
            </td>
          </tr>
          <tr>
            <td id="subsection4-name">
              Goods and Services received where Place of Supply is Malta other
              than those reported in Box 3
            </td>
            <td id="subsection4-box-name" className="box">
              Box 4
            </td>
            <td
              id="subsection4-value"
              className="rightalign value-field"
              tabIndex={4}
            >
              {renderValueCell('4')}
            </td>
            <td id="subsection7-box-name" className="box">
              Box 7
            </td>
            <td
              id="subsection7-value"
              className="rightalign value-field"
              tabIndex={7}
            >
              {renderValueCell('7')}
            </td>
          </tr>
          <tr>
            <td id="subsection5-name" className="subtotal">
              Sub-Total
            </td>

            <td id="subsection5-box-name" className="box">
              Box 5
            </td>
            <td id="subsection5-value" className="rightalign" tabIndex={5}>
              {renderValueCell('5')}
            </td>
            <td id="subsection8-box-name" className="box">
              Box 8
            </td>
            <td id="subsection8-value" className="rightalign" tabIndex={8}>
              {renderValueCell('8')}
            </td>
          </tr>
        </tbody>
      </table>

      <div className="divide-20"></div>
      <table className="vatreporttable table">
        <thead>
          <tr>
            <th id="section101-name">Deductions of Self-Charged VAT</th>
            <th></th>
            <th style={{ width: 130, textAlign: 'center' }}>
              Value - <span className="currency-sign"></span>
            </th>
            <th></th>
            <th style={{ width: 130, textAlign: 'center' }}>
              Input Tax - <span className="currency-sign"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id="subsection9-name">
              IC Acquisitions of Goods excluding Capital Goods
            </td>

            <td id="subsection9-box-name" className="box">
              Box 9
            </td>
            <td
              id="subsection9-value"
              className="rightalign value-field"
              tabIndex={9}
            >
              {renderValueCell('9')}
            </td>
            <td id="subsection13-box-name" className="box">
              Box 13
            </td>
            <td
              id="subsection13-value"
              className="rightalign value-field"
              tabIndex={14}
            >
              {renderValueCell('13')}
            </td>
          </tr>
          <tr>
            <td id="subsection9a-name">
              Services and goods received from EU Member States where the
              purchaser is liable for VAT
            </td>
            <td id="subsection9a-box-name" className="box">
              Box 9a
            </td>
            <td
              id="subsection9a-value"
              className="rightalign value-field"
              tabIndex={10}
            >
              {renderValueCell('9a')}
            </td>
            <td id="subsection13a-box-name" className="box">
              Box 13a
            </td>
            <td
              id="subsection13a-value"
              className="rightalign value-field"
              tabIndex={15}
            >
              {renderValueCell('13a')}
            </td>
          </tr>
          <tr>
            <td id="subsection10-name">IC Acquisitions of Capital Goods</td>
            <td id="subsection10-box-name" className="box">
              Box 10
            </td>
            <td
              id="subsection10-value"
              className="rightalign value-field"
              tabIndex={11}
            >
              {renderValueCell('10')}
            </td>
            <td id="subsection14-box-name" className="box">
              Box 14
            </td>
            <td
              id="subsection14-value"
              className="rightalign value-field"
              tabIndex={16}
            >
              {renderValueCell('14')}
            </td>
          </tr>
          <tr>
            <td id="subsection11-name">
              Goods and Services Received where Place of Supply is Malta
            </td>
            <td id="subsection11-box-name" className="box">
              Box 11
            </td>
            <td
              id="subsection11-value"
              className="rightalign value-field"
              tabIndex={12}
            >
              {renderValueCell('11')}
            </td>
            <td id="subsection15-box-name" className="box">
              Box 15
            </td>
            <td
              id="subsection15-value"
              className="rightalign value-field"
              tabIndex={17}
            >
              {renderValueCell('15')}
            </td>
          </tr>
          <tr>
            <td id="subsection12-name" className="subtotal">
              Sub-Total
            </td>
            <td id="subsection12-box-name" className="box">
              Box 12
            </td>
            <td id="subsection12-value" className="rightalign" tabIndex={13}>
              {renderValueCell('12')}
            </td>
            <td id="subsection16-box-name" className="box">
              Box 16
            </td>
            <td id="subsection16-value" className="rightalign" tabIndex={18}>
              {renderValueCell('16')}
            </td>
          </tr>
          <tr>
            <td colSpan={5} className="nbl nbt nbr space20"></td>
          </tr>
          <tr>
            <td id="subsection17-name" className="subtotal-2" colSpan={3}>
              (Box 8 - Box 16)
            </td>
            <td id="subsection17-box-name" className="box">
              Box 17
            </td>
            <td
              id="subsection17-value"
              className="rightalign value-field"
              tabIndex={19}
            >
              {renderValueCell('17')}
            </td>
          </tr>
        </tbody>
      </table>

      <div className="divide-20"></div>
      <table className="vatreporttable table">
        <thead>
          <tr>
            <th id="section102-name">Domestic Supplies & Exports</th>
            <th></th>
            <th style={{ width: 130, textAlign: 'center' }}>
              Value - <span className="currency-sign"></span>
            </th>
            <th></th>
            <th style={{ width: 130, textAlign: 'center' }}>
              Output Tax - <span className="currency-sign"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id="subsection18-name">Taxable Goods/Services @ 18%</td>
            <td id="subsection18-box-name" className="box">
              Box 18
            </td>
            <td
              id="subsection18-value"
              className="rightalign value-field"
              tabIndex={20}
            >
              {renderValueCell('18')}
            </td>
            <td id="subsection23-box-name" className="box">
              Box 23
            </td>
            <td
              id="subsection23-value"
              className="rightalign value-field"
              tabIndex={26}
            >
              {renderValueCell('23')}
            </td>
          </tr>
          <tr>
            <td id="subsection18a-name">Taxable Services @7%</td>
            <td id="subsection18a-box-name" className="box">
              Box 18a
            </td>
            <td
              id="subsection18a-value"
              className="rightalign value-field"
              tabIndex={21}
            >
              {renderValueCell('18a')}
            </td>
            <td id="subsection23a-box-name" className="box">
              Box 23a
            </td>
            <td
              id="subsection23a-value"
              className="rightalign value-field"
              tabIndex={27}
            >
              {renderValueCell('23a')}
            </td>
          </tr>
          <tr>
            <td id="subsection18b-name">Taxable Services @12%</td>
            <td id="subsection18b-box-name" className="box">
              Box 18b
            </td>
            <td
              id="subsection18b-value"
              className="rightalign value-field"
              tabIndex={21}
            >
              {renderValueCell('18b')}
            </td>
            <td id="subsection23b-box-name" className="box">
              Box 23b
            </td>
            <td
              id="subsection23b-value"
              className="rightalign value-field"
              tabIndex={27}
            >
              {renderValueCell('23b')}
            </td>
          </tr>
          <tr>
            <td id="subsection19-name">Taxable Goods/Services @ 5%</td>
            <td id="subsection19-box-name" className="box">
              Box 19
            </td>
            <td
              id="subsection19-value"
              className="rightalign value-field"
              tabIndex={22}
            >
              {renderValueCell('19')}
            </td>
            <td id="subsection24-box-name" className="box">
              Box 24
            </td>
            <td
              id="subsection24-value"
              className="rightalign value-field"
              tabIndex={28}
            >
              {renderValueCell('24')}
            </td>
          </tr>
          <tr>
            <td id="subsection20-name">Exempt with Credit / Exports</td>
            <td id="subsection20-box-name" className="box">
              Box 20
            </td>
            <td
              id="subsection20-value"
              className="rightalign value-field"
              tabIndex={23}
            >
              {renderValueCell('20')}
            </td>
            <td className="nbr nbb"></td>
            <td className="nbb"></td>
          </tr>
          <tr>
            <td id="subsection21-name">Exempt without Credit</td>
            <td id="subsection21-box-name" className="box">
              Box 21
            </td>
            <td
              id="subsection21-value"
              className="rightalign value-field"
              tabIndex={24}
            >
              {renderValueCell('21')}
            </td>
            <td className="nbr nbb"></td>
            <td className="nbb"></td>
          </tr>
          <tr>
            <td id="subsection22-name" className="subtotal">
              Sub-Total
            </td>
            <td id="subsection22-box-name" className="box">
              Box 22
            </td>
            <td id="subsection22-value" className="rightalign" tabIndex={25}>
              {renderValueCell('22')}
            </td>
            <td className="nbr"></td>
            <td className=""></td>
          </tr>
          <tr>
            <td id="subsection25-name" className="subtotal" colSpan={3}>
              Box 25
            </td>
            <td id="subsection25-box-name" className="box">
              Box 25
            </td>
            <td
              id="subsection25-value"
              className="rightalign value-field"
              tabIndex={30}
            >
              {renderValueCell('25')}
            </td>
          </tr>
          <tr>
            <td colSpan={5} className="nbl nbt nbr space20"></td>
          </tr>
          <tr>
            <td id="subsection26-name" className="subtotal-2" colSpan={3}>
              (Box 17 - Box 25)
            </td>
            <td id="subsection26-box-name" className="box">
              Box 26
            </td>
            <td
              id="subsection26-value"
              className="rightalign value-field"
              tabIndex={30}
            >
              {renderValueCell('26')}
            </td>
          </tr>
        </tbody>
      </table>

      <div className="divide-20"></div>
      <table className="vatreporttable table">
        <thead>
          <tr>
            <th id="section103-name">Domestic Purchases & Imports</th>
            <th></th>
            <th style={{ width: 130, textAlign: 'center' }}>
              Value - <span className="currency-sign"></span>
            </th>
            <th></th>
            <th style={{ width: 130, textAlign: 'center' }}>
              Input Tax - <span className="currency-sign"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td id="subsection27-name">Taxable Purchases for re-sale @ 18%</td>
            <td id="subsection27-box-name" className="box">
              Box 27
            </td>
            <td
              id="subsection27-value"
              className="rightalign value-field"
              tabIndex={31}
            >
              {renderValueCell('27')}
            </td>
            <td id="subsection34-box-name" className="box">
              Box 34
            </td>
            <td
              id="subsection34-value"
              className="rightalign value-field"
              tabIndex={38}
            >
              {renderValueCell('34')}
            </td>
          </tr>
          <tr>
            <td id="subsection28-name">Taxable Purchases for re-sale @ 5%</td>
            <td id="subsection28-box-name" className="box">
              Box 28
            </td>
            <td
              id="subsection28-value"
              className="rightalign value-field"
              tabIndex={32}
            >
              {renderValueCell('28')}
            </td>
            <td id="subsection35-box-name" className="box">
              Box 35
            </td>
            <td
              id="subsection35-value"
              className="rightalign value-field"
              tabIndex={39}
            >
              {renderValueCell('35')}
            </td>
          </tr>
          <tr>
            <td id="subsection29-name">Exempt Purchases for re-sale</td>
            <td id="subsection29-box-name" className="box">
              Box 29
            </td>
            <td
              id="subsection29-value"
              className="rightalign value-field"
              tabIndex={32}
            >
              {renderValueCell('29')}
            </td>
            <td className="nbr "></td>
            <td className=""></td>
          </tr>
          <tr>
            <td id="subsection30-name">Capital Goods</td>
            <td id="subsection30-box-name" className="box">
              Box 30
            </td>
            <td
              id="subsection30-value"
              className="rightalign value-field"
              tabIndex={33}
            >
              {renderValueCell('30')}
            </td>
            <td id="subsection36-box-name" className="box">
              Box 36
            </td>
            <td
              id="subsection36-value"
              className="rightalign value-field"
              tabIndex={40}
            >
              {renderValueCell('36')}
            </td>
          </tr>
          <tr>
            <td id="subsection31-name">Services & Overheads @ 18%</td>
            <td id="subsection31-box-name" className="box">
              Box 31
            </td>
            <td
              id="subsection31-value"
              className="rightalign value-field"
              tabIndex={34}
            >
              {renderValueCell('31')}
            </td>
            <td id="subsection37-box-name" className="box">
              Box 37
            </td>
            <td
              id="subsection37-value"
              className="rightalign value-field"
              tabIndex={41}
            >
              {renderValueCell('37')}
            </td>
          </tr>
          <tr>
            <td id="subsection31a-name">Services & Overheads @ 7%</td>
            <td id="subsection31a-box-name" className="box">
              Box 31a
            </td>
            <td
              id="subsection31a-value"
              className="rightalign value-field"
              tabIndex={35}
            >
              {renderValueCell('31a')}
            </td>
            <td id="subsection37a-box-name" className="box">
              Box 37a
            </td>
            <td
              id="subsection37a-value"
              className="rightalign value-field"
              tabIndex={42}
            >
              {renderValueCell('37a')}
            </td>
          </tr>
          <tr>
            <td id="subsection31b-name">Services & Overheads @ 12%</td>
            <td id="subsection31b-box-name" className="box">
              Box 31b
            </td>
            <td
              id="subsection31b-value"
              className="rightalign value-field"
              tabIndex={35}
            >
              {renderValueCell('31b')}
            </td>
            <td id="subsection37b-box-name" className="box">
              Box 37b
            </td>
            <td
              id="subsection37b-value"
              className="rightalign value-field"
              tabIndex={42}
            >
              {renderValueCell('37b')}
            </td>
          </tr>
          <tr>
            <td id="subsection32-name">Services & Overheads @ 5%</td>
            <td id="subsection32-box-name" className="box">
              Box 32
            </td>
            <td
              id="subsection32-value"
              className="rightalign value-field"
              tabIndex={36}
            >
              {renderValueCell('32')}
            </td>
            <td id="subsection38-box-name" className="box">
              Box 38
            </td>
            <td
              id="subsection38-value"
              className="rightalign value-field"
              tabIndex={43}
            >
              {renderValueCell('38')}
            </td>
          </tr>
          <tr>
            <td id="subsection33-name" className="subtotal">
              Sub-Total
            </td>
            <td id="subsection33-box-name" className="box">
              Box 33
            </td>
            <td id="subsection33-value" className="rightalign" tabIndex={37}>
              {renderValueCell('33')}
            </td>
            <td className="nbr "></td>
            <td className=""></td>
          </tr>

          <tr>
            <td id="subsection39-name" className="subtotal" colSpan={3}>
              Box 39
            </td>
            <td id="subsection39-box-name" className="box">
              Box 40
            </td>
            <td
              id="subsection39-value"
              className="rightalign value-field"
              tabIndex={45}
            >
              {renderValueCell('39')}
            </td>
          </tr>
          <tr>
            <td colSpan={5} className="nbl nbt nbr space20"></td>
          </tr>
          <tr>
            <td id="subsection40-name" className="subtotal-2" colSpan={3}>
              Adj in favour of Dept (not yet available)
            </td>
            <td id="subsection40-box-name" className="notavailable box">
              Box 40
            </td>
            <td
              id="subsection40-value"
              className="rightalign value-field notavailable"
              tabIndex={45}
            >
              {renderValueCell('40')}
            </td>
          </tr>
          <tr>
            <td colSpan={5} className="nbl nbt nbr space20"></td>
          </tr>
          <tr>
            <td id="subsection41-name" className="subtotal-2" colSpan={3}>
              Adj in your favour (not yet available)
            </td>
            <td id="subsection41-box-name" className="box notavailable">
              Box 41
            </td>
            <td
              id="subsection41-value"
              className="rightalign value-field notavailable"
              tabIndex={46}
            >
              {renderValueCell('41')}
            </td>
          </tr>
          <tr>
            <td colSpan={5} className="nbl nbt nbr space20"></td>
          </tr>
          <tr>
            <td id="subsection42-name" className="subtotal-2" colSpan={3}>
              Excess Credit (39 - 26) + (41 - 40)
            </td>
            <td id="subsection42-box-name" className="box">
              Box 42
            </td>
            <td
              id="subsection42-value"
              className="rightalign value-field"
              tabIndex={47}
            >
              {renderValueCell('42')}
            </td>
          </tr>
          <tr>
            <td colSpan={5} className="nbl nbt nbr space20"></td>
          </tr>
          <tr>
            <td id="subsection43-name" className="subtotal-2" colSpan={3}>
              Tax Payable (26 - 39) + (40 - 41){' '}
            </td>
            <td id="subsection43-box-name" className="box">
              Box 43
            </td>
            <td
              id="subsection43-value"
              className="rightalign value-field"
              tabIndex={48}
            >
              {renderValueCell('43')}
            </td>
          </tr>
          <tr>
            <td colSpan={5} className="nbl nbt nbr space20"></td>
          </tr>
          <tr>
            <td id="subsection44-name" className="subtotal-2" colSpan={3}>
              Excess Credit B/F (not yet available)
            </td>
            <td id="subsection44-box-name" className="box notavailable">
              Box 44
            </td>
            <td
              id="subsection44-value"
              className="rightalign value-field notavailable"
              tabIndex={49}
            >
              {renderValueCell('44')}
            </td>
          </tr>
          <tr>
            <td colSpan={5} className="nbl nbt nbr space20"></td>
          </tr>
          <tr>
            <td id="subsection45-name" className="subtotal-2" colSpan={3}>
              Tax Payable (43 - 44)
            </td>
            <td id="subsection45-box-name" className="box">
              Box 45
            </td>
            <td
              id="subsection45-value"
              className="rightalign value-field"
              tabIndex={50}
            >
              {renderValueCell('45')}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
